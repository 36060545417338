import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Chip,
  TextField,
  InputAdornment,
  Fade,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Autocomplete,
  Paper,
  Tooltip,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
  Search as SearchIcon,
  FilterList as FilterIcon,
  Close as CloseIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import useStore from '../../store/useStore';
import { BikeImage, Tag } from '../../types';
import { api, API_URL } from '../../services/api';

const isDevelopment = process.env.NODE_ENV === 'development';
const API_PREFIX = isDevelopment ? '/api' : '';

interface ImageManagerProps {
  onSuccess?: (message: string) => void;
}

interface EditImageFormData {
  id: string;
  publicId: string;
  title: string;
  description: string;
  selectedTags: Tag[];
  url: string;
}

export const ImageManager: React.FC<ImageManagerProps> = ({ onSuccess }) => {
  const { images, setImages, removeImage, tags, setTags } = useStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingImageId, setDeletingImageId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  
  // Edit functionality state
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editFormData, setEditFormData] = useState<EditImageFormData | null>(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const data = await api.getImages();
        if (data.success) {
          setImages(data.images);
        }
      } catch (error) {
        setError(error instanceof Error ? error.message : 'Failed to fetch images');
      } finally {
        setIsLoading(false);
      }
    };

    fetchImages();
  }, [setImages]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const data = await api.getTags();
        if (data.success) {
          setTags(data.tags);
        }
      } catch (error) {
        console.error('Failed to fetch tags:', error);
      }
    };

    fetchTags();
  }, [setTags]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, imageId: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedImage(imageId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedImage(null);
  };

  const handleDelete = async (publicId: string) => {
    setIsDeleting(true);
    setDeletingImageId(publicId);
    setError(null);

    try {
      const urlPublicId = publicId.replace('lavieavelo/', '');
      const response = await fetch(`${API_URL}${API_PREFIX}/images/${urlPublicId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        const data = await response.json().catch(() => ({ error: 'Failed to delete image' }));
        throw new Error(data.error || 'Failed to delete image');
      }

      const data = await response.json();
      if (!data.success) {
        throw new Error(data.error || 'Failed to delete image');
      }

      removeImage(publicId);
      handleMenuClose();
      
      const successMessage = 'Image supprimée avec succès !';
      
      if (onSuccess) {
        onSuccess(successMessage);
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to delete image');
    } finally {
      setIsDeleting(false);
      setDeletingImageId(null);
    }
  };

  const handleEditClick = (image: BikeImage) => {
    setEditFormData({
      id: image.id,
      publicId: image.publicId,
      title: image.title || '',
      description: image.description || '',
      selectedTags: image.tags || [],
      url: image.url,
    });
    setIsEditDialogOpen(true);
    handleMenuClose();
  };

  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false);
    setEditFormData(null);
    setError(null);
  };

  const handleEditFormChange = (field: keyof Omit<EditImageFormData, 'id' | 'publicId' | 'url' | 'selectedTags'>, value: string) => {
    if (editFormData) {
      setEditFormData(prev => ({
        ...prev!,
        [field]: value
      }));
    }
  };

  const handleTagsChange = (_event: React.SyntheticEvent, newValue: Tag[]) => {
    if (editFormData) {
      setEditFormData(prev => ({
        ...prev!,
        selectedTags: newValue
      }));
    }
  };

  const handleEditSubmit = async () => {
    if (!editFormData) return;

    setIsEditing(true);
    setError(null);

    try {
      const urlPublicId = editFormData.publicId.replace('lavieavelo/', '');
      
      const response = await fetch(`${API_URL}${API_PREFIX}/images/${urlPublicId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title: editFormData.title,
          description: editFormData.description,
          tagIds: editFormData.selectedTags.map(tag => tag.id),
        }),
      });

      if (!response.ok) {
        const data = await response.json().catch(() => ({ error: 'Failed to update image' }));
        throw new Error(data.error || 'Failed to update image');
      }

      const data = await response.json();
      
      if (!data.success) {
        throw new Error(data.error || 'Failed to update image');
      }
      
      setImages(prevImages => 
        prevImages.map(img => 
          img.publicId === editFormData.publicId 
            ? data.image
            : img
        )
      );

      handleEditDialogClose();
      
      if (onSuccess) {
        onSuccess('Image mise à jour avec succès !');
      }
    } catch (error) {
      console.error('Failed to update image:', error);
      setError(error instanceof Error ? error.message : 'Failed to update image');
    } finally {
      setIsEditing(false);
    }
  };

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleTagSelect = (tagId: string) => {
    setSelectedTags(prev => {
      const isSelected = prev.includes(tagId);
      if (isSelected) {
        return prev.filter(id => id !== tagId);
      } else {
        return [...prev, tagId];
      }
    });
  };

  const clearTags = () => {
    setSelectedTags([]);
    handleFilterClose();
  };

  const filteredImages = images.filter(image => {
    const searchLower = searchTerm.toLowerCase().trim();
    const matchesSearch = searchLower === '' || 
      image.title?.toLowerCase().includes(searchLower) ||
      image.description?.toLowerCase().includes(searchLower);

    const matchesTags = selectedTags.length === 0 || 
      image.tags?.some(tag => selectedTags.includes(tag.id));

    return matchesSearch && matchesTags;
  });

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ mb: 3, display: 'flex', gap: 2, alignItems: 'center' }}>
        <TextField
          placeholder="Rechercher des images..."
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ width: 300 }}
        />

        <Button
          variant="outlined"
          onClick={handleFilterClick}
          endIcon={<KeyboardArrowDownIcon />}
          size="medium"
          sx={{
            borderColor: '#016236',
            color: '#016236',
            '&:hover': {
              borderColor: '#016236',
              bgcolor: 'rgba(1, 98, 54, 0.08)'
            }
          }}
        >
          Filtrer par collection
        </Button>

        {selectedTags.length > 0 && (
          <Stack direction="row" spacing={1}>
            {selectedTags.map(tagId => {
              const tag = tags.find(t => t.id === tagId);
              return tag ? (
                <Chip
                  key={tag.id}
                  label={tag.name}
                  size="small"
                  onDelete={() => handleTagSelect(tag.id)}
                  color="primary"
                />
              ) : null;
            })}
            <Chip
              label="Effacer tout"
              size="small"
              onClick={clearTags}
              variant="outlined"
            />
          </Stack>
        )}

        <Menu
          anchorEl={filterAnchorEl}
          open={Boolean(filterAnchorEl)}
          onClose={handleFilterClose}
          PaperProps={{
            style: {
              maxHeight: 300,
            },
          }}
        >
          {tags.map((tag) => (
            <MenuItem 
              key={tag.id}
              onClick={() => handleTagSelect(tag.id)}
              sx={{ 
                bgcolor: selectedTags.includes(tag.id) ? 'action.selected' : 'inherit',
                '&:hover': {
                  bgcolor: selectedTags.includes(tag.id) ? 'action.selected' : 'action.hover',
                }
              }}
            >
              {tag.name}
            </MenuItem>
          ))}
        </Menu>
      </Box>

      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
          <CircularProgress />
        </Box>
      ) : filteredImages.length === 0 ? (
        <Typography variant="body1" color="text.secondary" align="center" sx={{ p: 5 }}>
          Aucune image trouvée
        </Typography>
      ) : (
        <Grid container spacing={2}>
          {filteredImages.map((image) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={image.id}>
              <Card sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                position: 'relative',
              }}>
                {deletingImageId === image.publicId && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      zIndex: 1,
                    }}
                  >
                    <CircularProgress sx={{ color: 'white' }} />
                  </Box>
                )}
                <Box
                  sx={{
                    position: 'relative',
                    width: '100%',
                    aspectRatio: '1/1',
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    component="img"
                    src={image.url}
                    alt={image.title}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      bgcolor: 'rgba(255, 255, 255, 0.8)',
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                      },
                    }}
                    onClick={(e) => handleMenuOpen(e, image.publicId)}
                    disabled={deletingImageId === image.publicId}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Box>
                <CardContent sx={{ flexGrow: 1, p: 1.5 }}>
                  <Typography variant="subtitle1" component="h2" gutterBottom noWrap>
                    {image.title}
                  </Typography>
                  {image.description && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        mb: 1,
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: '0.875rem',
                      }}
                    >
                      {image.description}
                    </Typography>
                  )}
                  
                  {image.tags && image.tags.length > 0 && (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {image.tags.map((tag) => (
                        <Chip
                          key={tag.id}
                          label={tag.name}
                          size="small"
                          color="primary"
                          variant="outlined"
                          sx={{ 
                            mb: 0.5,
                            height: '20px',
                            borderColor: '#016236',
                            color: '#016236',
                            '& .MuiChip-label': {
                              fontSize: '0.75rem',
                              px: 1,
                            }
                          }}
                        />
                      ))}
                    </Box>
                  )}
                  
                  <Typography 
                    variant="caption" 
                    color="text.secondary" 
                    display="block" 
                    sx={{ 
                      mt: 0.5,
                      fontSize: '0.75rem'
                    }}
                  >
                    {new Date(image.createdAt).toLocaleDateString('fr-FR')}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={() => {
          const image = images.find(img => img.publicId === selectedImage);
          if (image) handleEditClick(image);
        }}>
          <EditIcon fontSize="small" sx={{ mr: 1, color: '#016236' }} />
          Modifier
        </MenuItem>
        <MenuItem 
          onClick={() => selectedImage && handleDelete(selectedImage)}
          disabled={isDeleting}
          sx={{
            color: 'error.main',
            '&.Mui-disabled': {
              color: 'error.main',
              opacity: 0.5,
            },
          }}
        >
          {isDeleting ? (
            <>
              <CircularProgress size={20} sx={{ mr: 1 }} color="error" />
              Suppression...
            </>
          ) : (
            <>
              <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
              Supprimer
            </>
          )}
        </MenuItem>
      </Menu>

      <Dialog 
        open={isEditDialogOpen} 
        onClose={handleEditDialogClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          pb: 1
        }}>
          <Typography variant="h6">Modifier l'image</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleEditDialogClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        
        <DialogContent sx={{ py: 2 }}>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          
          {editFormData && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Paper
                  sx={{
                    p: 3,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    component="img"
                    src={editFormData.url}
                    alt={editFormData.title}
                    sx={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'contain',
                      borderRadius: 1,
                    }}
                  />
                </Paper>
              </Grid>

              <Grid item xs={12} md={8}>
                <Paper sx={{ p: 3, height: '100%' }}>
                  <Stack spacing={3}>
                    {editFormData.selectedTags.length > 0 && (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {editFormData.selectedTags.map((tag) => (
                          <Chip
                            key={tag.id}
                            label={tag.name}
                            color="success"
                            onDelete={() => {
                              const newTags = editFormData.selectedTags.filter(t => t.id !== tag.id);
                              handleTagsChange(null as any, newTags);
                            }}
                            sx={{ m: 0.5 }}
                          />
                        ))}
                      </Box>
                    )}

                    <Autocomplete
                      multiple
                      options={tags}
                      value={editFormData.selectedTags}
                      onChange={handleTagsChange}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderTags={() => null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tags"
                          placeholder={editFormData.selectedTags.length ? "Ajouter plus de tags" : "Sélectionnez des tags"}
                          helperText="Sélectionnez un ou plusieurs tags pour cette image"
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': {
                                borderColor: '#016236'
                              }
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                              color: '#016236'
                            }
                          }}
                        />
                      )}
                    />

                    <TextField
                      fullWidth
                      label="Titre de l'image"
                      required
                      value={editFormData.title}
                      onChange={(e) => handleEditFormChange('title', e.target.value)}
                      placeholder="Donnez un titre à votre image"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: '#016236'
                          }
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#016236'
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#016236'
                        }
                      }}
                    />

                    <TextField
                      fullWidth
                      label="Description"
                      multiline
                      rows={3}
                      value={editFormData.description}
                      onChange={(e) => handleEditFormChange('description', e.target.value)}
                      placeholder="Ajoutez une description (optionnel)"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: '#016236'
                          }
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#016236'
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#016236'
                        }
                      }}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                      <Button 
                        onClick={handleEditDialogClose} 
                        sx={{ 
                          mr: 2,
                          color: '#016236',
                          '&:hover': {
                            bgcolor: 'rgba(1, 98, 54, 0.04)'
                          }
                        }}
                      >
                        Annuler
                      </Button>
                      <Button 
                        onClick={handleEditSubmit}
                        variant="contained"
                        disabled={isEditing || !editFormData?.title.trim()}
                        sx={{
                          bgcolor: '#016236',
                          '&:hover': {
                            bgcolor: '#016236e0'
                          }
                        }}
                      >
                        {isEditing ? (
                          <>
                            <CircularProgress size={20} sx={{ mr: 1 }} />
                            Enregistrement...
                          </>
                        ) : (
                          'Enregistrer'
                        )}
                      </Button>
                    </Box>
                  </Stack>
                </Paper>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}; 