import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Container, 
  Box,
  IconButton,
  MenuItem,
  Avatar,
  Divider,
  FormControl,
  Select,
  InputLabel,
  SelectChangeEvent,
  List,
  ListItem,
  SwipeableDrawer
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../hooks/useAuth';
import useStore from '../store/useStore';
import bikeLogo from '../assets/bikepas.png';
import { api } from '../services/api';

const Footer = styled('footer')(({ theme }) => ({
  marginTop: 'auto',
  backgroundColor: '#016236',
  padding: theme.spacing(2),
  color: theme.palette.primary.contrastText,
}));

const StyledNavLink = styled(Link)<{ isactive: string }>(({ isactive }) => ({
  color: '#016236',
  textDecoration: 'none',
  opacity: isactive === 'true' ? 1 : 0.9,
  fontWeight: isactive === 'true' ? 600 : 400,
  position: 'relative',
  padding: '4px 8px',
  transition: 'all 0.3s ease',
  '&:hover': {
    opacity: 1,
  }
}));

const MobileNav = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
  },
}));

const DesktopNav = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const StyledDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: '#212121',
    width: 240,
    padding: theme.spacing(1),
    paddingTop: '80px',
    zIndex: 9998,
    height: 'auto',
    maxHeight: '100%',
    borderBottomLeftRadius: theme.spacing(2),
  },
}));

const DrawerLink = styled(Link)(({ theme }) => ({
  color: 'white',
  textDecoration: 'none',
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  display: 'block',
  transition: 'all 0.2s ease',
  fontFamily: '"Roboto Condensed", sans-serif',
  fontSize: '1.1rem',
  fontWeight: 500,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
}));

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { tags, selectedTag, setSelectedTag, setTags, images } = useStore();
  const isDashboard = location.pathname === '/dashboard';
  const isCollection = location.pathname === '/';
  
  // Get only tags that are actually used in images
  const usedTagIds = new Set(images.flatMap(image => image.tags?.map(tag => tag.id) || []));
  const usedTags = tags
    .filter(tag => usedTagIds.has(tag.id))
    .sort((a, b) => a.name.localeCompare(b.name, 'fr', { sensitivity: 'base' }));
  const hasUsedTags = usedTags.length > 0;

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const data = await api.getTags();
        if (data.success) {
          setTags(data.tags);
        }
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };

    fetchTags();
  }, [setTags]);

  const handleTagChange = (event: SelectChangeEvent<string>) => {
    setSelectedTag(event.target.value);
  };

  const handleResetCollection = () => {
    setSelectedTag('');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar 
        position={isDashboard ? "fixed" : "static"}
        sx={{
          zIndex: 9999,
          backgroundColor: '#f5e08c',
          color: '#016236',
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
          borderBottom: '1px solid',
          borderColor: 'rgba(1, 98, 54, 0.1)',
        }}
      >
        <Toolbar 
          sx={{ 
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
            px: { xs: 2, sm: 4 },
            maxWidth: '1200px',
            width: '100%',
            margin: '0 auto',
            height: { xs: 64, sm: 80 }
          }}
        >
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: { xs: 1, sm: 2 }
          }}>
            <Link to="/" onClick={handleResetCollection}>
              <img 
                src={bikeLogo} 
                alt="La Vie à Vélo Logo" 
                style={{
                  height: '30px',
                  width: 'auto',
                  objectFit: 'contain',
                  opacity: 0.9
                }}
              />
            </Link>
            
            <Typography 
              variant="h6" 
              component={Link} 
              to="/" 
              onClick={handleResetCollection}
              sx={{ 
                color: '#016236',
                textDecoration: 'none', 
                fontWeight: 700,
                fontSize: { xs: '1.2rem', sm: '1.6rem' },
                whiteSpace: 'nowrap',
                fontFamily: '"Roboto Condensed", sans-serif'
              }}
            >
              VIE À VÉLO
            </Typography>
          </Box>

          <DesktopNav sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 3,
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            fontSize: '1.2rem',
            fontFamily: '"Roboto Condensed", sans-serif'
          }}>
            <StyledNavLink to="/" isactive={(location.pathname === '/').toString()} onClick={handleResetCollection}>
              ACCUEIL
            </StyledNavLink>
            <StyledNavLink to="/about" isactive={(location.pathname === '/about').toString()}>
              A PROPOS
            </StyledNavLink>
          </DesktopNav>

          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}>
            {isCollection && hasUsedTags && (
              <Box sx={{ 
                display: { xs: 'none', md: 'block' }
              }}>
                <Box 
                  sx={{ 
                    position: 'relative',
                    zIndex: 10000,
                    minWidth: 150
                  }}
                >
                  <FormControl 
                    size="small" 
                    fullWidth
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    sx={{ 
                      '& .MuiOutlinedInput-root': {
                        color: '#016236',
                        '& fieldset': {
                          borderColor: '#016236',
                        },
                        '&:hover fieldset': {
                          borderColor: '#016236',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#016236',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#016236',
                        '&.Mui-focused': {
                          color: '#016236'
                        }
                      },
                      '& .MuiSvgIcon-root': {
                        color: '#016236',
                      },
                      '& .MuiSelect-select': {
                        color: '#016236',
                      }
                    }}
                  >
                    <InputLabel id="collection-select-label">Collections</InputLabel>
                    <Select
                      labelId="collection-select-label"
                      id="collection-select"
                      value={selectedTag}
                      label="Collections"
                      onChange={handleTagChange}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      MenuProps={{
                        container: document.body,
                        style: { zIndex: 10001 },
                        PaperProps: {
                          style: { 
                            zIndex: 10001,
                            marginTop: '8px',
                            backgroundColor: '#fff'
                          }
                        }
                      }}
                    >
                      <MenuItem value="">
                        <em>Accueil</em>
                      </MenuItem>
                      {usedTags.map((tag) => (
                        <MenuItem key={tag.id} value={tag.id}>
                          {tag.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            )}
            <MobileNav>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => setMobileMenuOpen(true)}
                sx={{ color: '#016236' }}
              >
                <MenuIcon />
              </IconButton>
            </MobileNav>
            {user && (
              <>
                <IconButton
                  onClick={() => navigate('/dashboard')}
                  size="small"
                  sx={{ 
                    display: { xs: 'none', md: 'flex' },
                    border: '2px solid #016236',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: '#016236',
                      '& .MuiAvatar-root': {
                        backgroundColor: '#f5e08c',
                        color: '#016236',
                      }
                    }
                  }}
                >
                  <Avatar 
                    sx={{ 
                      width: 32, 
                      height: 32, 
                      bgcolor: '#016236',
                      color: '#f5e08c',
                      fontSize: '0.875rem',
                      transition: 'all 0.2s ease',
                    }}
                  >
                    {user.email.charAt(0).toUpperCase()}
                  </Avatar>
                </IconButton>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      <StyledDrawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        onOpen={() => setMobileMenuOpen(true)}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <Box sx={{ width: 240 }}>
          <List>
            <ListItem>
              <DrawerLink 
                to="/" 
                onClick={() => {
                  setMobileMenuOpen(false);
                  handleResetCollection();
                }}
              >
                ACCUEIL
              </DrawerLink>
            </ListItem>
            <ListItem>
              <DrawerLink to="/about" onClick={() => setMobileMenuOpen(false)}>
                A PROPOS
              </DrawerLink>
            </ListItem>

            <Divider sx={{ my: 1, borderColor: 'rgba(255, 255, 255, 0.12)' }} />
            
            {isCollection && hasUsedTags && (
              <ListItem>
                <FormControl 
                  fullWidth
                  size="small"
                  sx={{ 
                    position: 'relative',
                    zIndex: 10000,
                    '& .MuiOutlinedInput-root': {
                      color: 'white',
                      '& fieldset': {
                        borderColor: 'rgba(255, 255, 255, 0.5)',
                      },
                      '&:hover fieldset': {
                        borderColor: 'white',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'white',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: 'rgba(255, 255, 255, 0.7)',
                      '&.Mui-focused': {
                        color: 'white'
                      }
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'white',
                    },
                    '& .MuiSelect-select': {
                      color: 'white',
                    }
                  }}
                >
                  <InputLabel id="mobile-collection-select-label">Collections</InputLabel>
                  <Select
                    labelId="mobile-collection-select-label"
                    id="mobile-collection-select"
                    value={selectedTag}
                    label="Collections"
                    onChange={(e) => {
                      handleTagChange(e);
                      setMobileMenuOpen(false);
                    }}
                    MenuProps={{
                      container: document.body,
                      style: { zIndex: 10001 },
                      PaperProps: {
                        style: { 
                          backgroundColor: '#212121',
                          color: 'white',
                          zIndex: 10001,
                          marginTop: '8px',
                          maxHeight: '50vh'
                        }
                      },
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                      }
                    }}
                  >
                    <MenuItem value="" sx={{ color: 'white' }}>
                      <em>Accueil</em>
                    </MenuItem>
                    {usedTags.map((tag) => (
                      <MenuItem key={tag.id} value={tag.id} sx={{ color: 'white' }}>
                        {tag.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ListItem>
            )}

            {user && (
              <>
                <Divider sx={{ my: 1, borderColor: 'rgba(255, 255, 255, 0.12)' }} />
                
                <ListItem sx={{ py: 0 }}>
                  <DrawerLink 
                    to="/dashboard"
                    onClick={() => setMobileMenuOpen(false)}
                    sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1.5,
                      color: 'white',
                    }}
                  >
                    <DirectionsBikeIcon fontSize="small" sx={{ color: 'white' }} />
                    Tableau de bord
                  </DrawerLink>
                </ListItem>
              </>
            )}
          </List>
        </Box>
      </StyledDrawer>

      <Container 
        component="main" 
        sx={{ 
          mt: isDashboard ? 8 : 4,
          mb: 4, 
          flex: 1,
          ...(isDashboard && {
            px: { xs: 0 },
            maxWidth: '100% !important'
          })
        }}
      >
        {children}
      </Container>

      {!isDashboard && (
        <Footer>
          <Container>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>

              <Typography variant="body2" align="center" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.5 }}>
                © {new Date().getFullYear()} La Vie à Vélo - Site réalisé par{' '}
                <a 
                  href="https://kiato-web.fr" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  style={{ 
                    color: 'inherit',
                    textDecoration: 'none',
                    fontWeight: 600
                  }}
                  onMouseOver={(e) => e.currentTarget.style.textDecoration = 'underline'}
                  onMouseOut={(e) => e.currentTarget.style.textDecoration = 'none'}
                >
                  Kiato-Web
                </a>
              </Typography>

            </Box>
          </Container>
        </Footer>
      )}
    </Box>
  );
};

export default Layout; 