import { useState } from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { 
  Typography, 
  TextField, 
  Button, 
  Alert,
  Box,
  Paper,
  Card,
  CardContent
} from '@mui/material';
import { LockReset as LockResetIcon } from '@mui/icons-material';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

const changePasswordSchema = z.object({
  currentPassword: z.string().min(6, 'Le mot de passe actuel doit contenir au moins 6 caractères'),
  newPassword: z.string().min(6, 'Le nouveau mot de passe doit contenir au moins 6 caractères'),
  confirmPassword: z.string().min(6, 'La confirmation du mot de passe doit contenir au moins 6 caractères'),
}).refine((data: { newPassword: string, confirmPassword: string }) => data.newPassword === data.confirmPassword, {
  message: "Les mots de passe ne correspondent pas",
  path: ["confirmPassword"],
});

type ChangePasswordFormData = z.infer<typeof changePasswordSchema>;

interface ChangePasswordFormProps {
  onSuccess: (message: string) => void;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ onSuccess }) => {
  const [error, setError] = useState<string>('');
  const [localSuccess, setLocalSuccess] = useState<string>('');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ChangePasswordFormData>({
    resolver: zodResolver(changePasswordSchema),
  });

  const onSubmit = async (data: ChangePasswordFormData) => {
    try {
      setError('');
      setLocalSuccess('');
      
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token non trouvé');
      }

      await axios.post(`${API_URL}/auth/change-password`, 
        {
          currentPassword: data.currentPassword,
          newPassword: data.newPassword,
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );

      setLocalSuccess('Mot de passe modifié avec succès');
      onSuccess('Mot de passe modifié avec succès');
      reset(); // Reset form fields
    } catch (err: any) {
      console.error('Erreur lors du changement de mot de passe:', err);
      setError(
        err.response?.data?.message || 
        'Une erreur est survenue lors du changement de mot de passe'
      );
    }
  };

  return (
    <Card sx={{ mb: 4 }}>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <LockResetIcon sx={{ color: '#016236', mr: 1 }} />
          <Typography variant="h5" component="h2">
            Changer le mot de passe
          </Typography>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              {...register('currentPassword')}
              label="Mot de passe actuel"
              type="password"
              error={!!errors.currentPassword}
              helperText={errors.currentPassword?.message as string}
              fullWidth
              size="small"
            />

            <TextField
              {...register('newPassword')}
              label="Nouveau mot de passe"
              type="password"
              error={!!errors.newPassword}
              helperText={errors.newPassword?.message as string}
              fullWidth
              size="small"
            />

            <TextField
              {...register('confirmPassword')}
              label="Confirmer le nouveau mot de passe"
              type="password"
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message as string}
              fullWidth
              size="small"
            />

            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}

            {localSuccess && (
              <Alert severity="success" sx={{ mt: 2 }}>
                {localSuccess}
              </Alert>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                sx={{ 
                  bgcolor: '#016236',
                  '&:hover': {
                    bgcolor: '#014025',
                  }
                }}
              >
                {isSubmitting ? 'Modification...' : 'Modifier le mot de passe'}
              </Button>
            </Box>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default ChangePasswordForm; 