import React, { useState } from 'react';
import {
  Box,
  Typography,
  useTheme,
  Paper,
  Grid,
  Card,
  CardContent,
  IconButton,
  Avatar,
  Badge,
  Stack,
  Alert,
  Fade,
  Drawer,
  useMediaQuery,
  Fab,
} from '@mui/material';
import {
  Notifications as NotificationsIcon,
  Settings as SettingsIcon,
  PhotoLibrary as PhotoLibraryIcon,
  CloudUpload as CloudUploadIcon,
  Edit as EditIcon,
  TrendingUp as TrendingUpIcon,
  CheckCircle as CheckCircleIcon,
  Menu as MenuIcon,
  Add as AddIcon,
  LockReset as LockResetIcon,
} from '@mui/icons-material';
import useStore from '../store/useStore';
import { ImageUploader } from '../components/dashboard/ImageUploader';
import { ImageManager } from '../components/dashboard/ImageManager';
import { Sidebar } from '../components/dashboard/Sidebar';
import TagManager from '../components/dashboard/TagManager';
import ChangePasswordForm from '../components/dashboard/ChangePasswordForm';

const DRAWER_WIDTH = 280;

const Dashboard: React.FC = () => {
  const theme = useTheme();
  const { homeContent, images } = useStore();
  const [activeSection, setActiveSection] = useState<'upload' | 'manage' | 'password'>('manage');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSuccess = (message: string) => {
    setSuccessMessage(message);
    // Auto-hide the success message after 5 seconds
    setTimeout(() => {
      setSuccessMessage(null);
    }, 5000);
  };

  const renderStats = () => {
    const thisMonth = images.filter(img => {
      const uploadDate = new Date(img.createdAt);
      const now = new Date();
      return uploadDate.getMonth() === now.getMonth();
    }).length;

    const lastUpdate = homeContent ? new Date(homeContent.updatedAt) : null;
    const formattedDate = lastUpdate ? new Intl.DateTimeFormat('fr-FR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(lastUpdate) : '-';

    return (
      <Stack spacing={1}>
        <Typography variant="h5" sx={{ mb: 2, fontWeight: 500 }}>
          Vue d'ensemble
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <PhotoLibraryIcon sx={{ color: '#016236', mr: 1 }} />
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    Galerie Totale
                  </Typography>
                </Box>
                <Typography variant="h3" component="div" sx={{ mb: 1, fontWeight: 600, color: '#016236' }}>
                  {images.length}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  images dans la galerie
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <EditIcon sx={{ color: '#016236', mr: 1 }} />
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    Dernière Mise à Jour
                  </Typography>
                </Box>
                <Typography variant="h6" component="div" sx={{ mb: 1, fontWeight: 500, color: '#016236' }}>
                  {formattedDate}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  du contenu d'accueil
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    );
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'upload':
        return (
          <>
            <ImageUploader onSuccess={handleSuccess} />
            <TagManager onSuccess={handleSuccess} />
          </>
        );
      case 'manage':
        return <ImageManager onSuccess={handleSuccess} />;
      case 'password':
        return <ChangePasswordForm onSuccess={handleSuccess} />;
      default:
        return <ImageManager onSuccess={handleSuccess} />;
    }
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      {/* Mobile Drawer */}
      {isMobile && (
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { 
              width: DRAWER_WIDTH,
              backgroundColor: '#212121',
              boxSizing: 'border-box',
            },
          }}
        >
          <Sidebar
            activeSection={activeSection}
            onSectionChange={(section) => {
              setActiveSection(section);
              setMobileOpen(false);
            }}
            drawerWidth={DRAWER_WIDTH}
          />
        </Drawer>
      )}

      {/* Desktop Sidebar */}
      {!isMobile && (
        <Sidebar
          activeSection={activeSection}
          onSectionChange={setActiveSection}
          drawerWidth={DRAWER_WIDTH}
        />
      )}

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: '100vh',
          backgroundColor: theme.palette.grey[100],
          ml: { xs: 0, md: `${DRAWER_WIDTH}px` },
          pt: { xs: '80px', md: '88px' },
          width: '100%',
        }}
      >
        {/* Content Header - Fixed */}
        <Paper
          elevation={0}
          sx={{
            position: 'fixed',
            top: 80,
            left: { xs: 0, md: DRAWER_WIDTH },
            right: 0,
            px: { xs: 2, sm: 3 },
            py: 2,
            background: theme.palette.background.paper,
            borderBottom: `1px solid ${theme.palette.divider}`,
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {isMobile && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, color: '#016236' }}
              >
                <MenuIcon />
              </IconButton>
            )}
            
            {successMessage ? (
              <Fade in={!!successMessage}>
                <Alert 
                  icon={<CheckCircleIcon fontSize="inherit" />}
                  severity="success"
                  sx={{ 
                    width: '100%',
                    '& .MuiAlert-message': { 
                      display: 'flex', 
                      alignItems: 'center',
                      fontWeight: 500
                    }
                  }}
                  onClose={() => setSuccessMessage(null)}
                >
                  {successMessage}
                </Alert>
              </Fade>
            ) : (
              <Typography 
                variant="h5" 
                component="h1" 
                sx={{ 
                  fontWeight: 500,
                  fontSize: { xs: '1.1rem', sm: '1.5rem' }
                }}
              >
                {activeSection === 'manage' && 'Gérer les Images'}
                {activeSection === 'upload' && 'Ajouter une Image'}
                {activeSection === 'password' && 'Sécurité du Compte'}
              </Typography>
            )}
          </Box>
        </Paper>

        {/* Main Content */}
        <Paper
          elevation={0}
          sx={{
            p: { xs: 2, sm: 3 },
            backgroundColor: theme.palette.background.paper,
            borderRadius: 2,
            mx: { xs: 1, sm: 2 },
            mt: 2,
          }}
        >
          {renderContent()}
        </Paper>
      </Box>

      {/* Mobile FAB for quick section change */}
      {isMobile && (
        <Fab
          color="primary"
          aria-label="change section"
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            bgcolor: '#016236',
            '&:hover': {
              bgcolor: '#014a29',
            },
          }}
          onClick={() => setActiveSection(activeSection === 'manage' ? 'upload' : 'manage')}
        >
          {activeSection === 'manage' ? <AddIcon /> : <PhotoLibraryIcon />}
        </Fab>
      )}
    </Box>
  );
};

export default Dashboard; 