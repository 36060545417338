import React, { useEffect, useState, useCallback } from 'react';
import { 
  Grid, 
  CardMedia,
  Typography, 
  Box, 
  Skeleton,
  styled,
  Modal,
  IconButton,
  Fade,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import useStore from '../store/useStore';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Keyboard, Autoplay } from 'swiper/modules';
import { SelectChangeEvent } from '@mui/material/Select';
import { api } from '../services/api';



const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  height: '90vh',
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const NavigationButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: 'rgba(255, 255, 255, 0.3)',
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  zIndex: 1,
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 16,
  right: 16,
  backgroundColor: 'rgba(255, 255, 255, 0.3)',
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  zIndex: 1,
}));

const ZoomedImage = styled('img')({
  maxWidth: '100%',
  maxHeight: '90%',
  objectFit: 'contain',
});

// Add a styled component for the title
const ImageTitle = styled(Typography)(({ theme }) => ({
  color: 'white',
  marginBottom: '16px',
  fontFamily: '"Playwrite IT Moderna", sans-serif',
  fontWeight: 500,
  textAlign: 'center',
  zIndex: 1,
}));

// Update swiperStyles to remove mobile centering
const swiperStyles = `
  .swiper-button-next,
  .swiper-button-prev {
    color: #016236;
    background-color: rgba(245, 224, 140, 0.8);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: all 0.3s ease;
    top: 50%;
  }

  .swiper-button-prev {
    left: 10px;
  }

  .swiper-button-next {
    right: 10px;
  }

  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background-color: rgba(245, 224, 140, 1);
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 20px;
  }

  .swiper-pagination,
  .swiper-pagination-custom {
    position: relative;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px 0;
  }

  .swiper-pagination-bullet {
    background: #016236;
    opacity: 0.5;
    width: 10px;
    height: 10px;
    margin: 0 5px;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: #016236;
    width: 12px;
    height: 12px;
  }

  .swiper-slide {
    height: auto;
    display: flex;
  }

  @media (max-width: 768px) {
    .swiper-wrapper {
      align-items: flex-start;
    }
    
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
    
    .swiper-pagination,
    .swiper-pagination-custom {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
  }
`;

// Add the styles to the document
const styleSheet = document.createElement("style");
styleSheet.textContent = swiperStyles;
document.head.appendChild(styleSheet);

const Collection: React.FC = () => {
  const { 
    images, 
    tags, 
    selectedTag, 
    setSelectedTag,
    isLoading: storeLoading, 
    error: storeError, 
    setImages, 
    setLoading, 
    setError 
  } = useStore();
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [localError, setLocalError] = useState<string | null>(null);

  // Get only tags that are actually used in images
  const usedTagIds = new Set(images.flatMap(image => image.tags?.map(tag => tag.id) || []));
  const usedTags = tags
    .filter(tag => usedTagIds.has(tag.id))
    .sort((a, b) => a.name.localeCompare(b.name, 'fr', { sensitivity: 'base' }));
  const hasUsedTags = usedTags.length > 0;

  // Add this to get the selected collection name
  const selectedCollection = usedTags.find(tag => tag.id === selectedTag);

  const handleTagChange = (event: SelectChangeEvent<string>) => {
    setSelectedTag(event.target.value);
  };

  useEffect(() => {
    const fetchImages = async () => {
      setLocalLoading(true);
      setLocalError(null);
      setLoading(true);
      
      try {
        const data = await api.getImages();
        if (data.success) {
          setImages(data.images);
        } else {
          throw new Error(data.error || 'Failed to fetch images');
        }
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Failed to fetch images';
        setLocalError(errorMessage);
        setError(errorMessage);
      } finally {
        setLocalLoading(false);
        setLoading(false);
      }
    };

    fetchImages();
  }, [setImages, setLoading, setError]);

  const filteredImages = images.filter(image => {
    if (!selectedTag) return true;
    return image.tags?.some(tag => tag.id === selectedTag);
  });

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index);
  };

  const handleCloseModal = () => {
    setSelectedImageIndex(null);
  };

  const handlePrevImage = () => {
    if (selectedImageIndex === null) return;
    setSelectedImageIndex((prev) => {
      if (prev === null) return 0;
      return prev === 0 ? filteredImages.length - 1 : prev - 1;
    });
  };

  const handleNextImage = () => {
    if (selectedImageIndex === null) return;
    setSelectedImageIndex((prev) => {
      if (prev === null) return 0;
      return prev === filteredImages.length - 1 ? 0 : prev + 1;
    });
  };

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (selectedImageIndex === null) return;
    
    switch (event.key) {
      case 'ArrowLeft':
        handlePrevImage();
        break;
      case 'ArrowRight':
        handleNextImage();
        break;
      case 'Escape':
        handleCloseModal();
        break;
    }
  }, [selectedImageIndex, handlePrevImage, handleNextImage, handleCloseModal]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const isLoading = storeLoading || localLoading;
  const error = storeError || localError;

  if (isLoading) {
    return (
      <Grid 
        container 
        spacing={3}
        justifyContent="center"
      >
        {[1, 2, 3, 4].map((n) => (
          <Grid item xs={12} sm={6} md={4} key={n}>
            <Skeleton variant="rectangular" height={200} />
          </Grid>
        ))}
      </Grid>
    );
  }

  if (error) {
    return (
      <Typography color="error" variant="h6" align="center">
        {error}
      </Typography>
    );
  }

  return (
    <Box>
      {/* Collection Dropdown - Only show on mobile if there are images with tags */}
      {hasUsedTags && (
        <Box sx={{ 
          mb: 4,
          display: { xs: 'flex', md: 'none' },
          justifyContent: 'center',
          width: '100%',
          px: { xs: 2, sm: 0 },
          position: 'relative',
          zIndex: 1
        }}>
          <FormControl 
            size="small" 
            sx={{ 
              width: '100%',
              position: 'relative',
              zIndex: 1,
              '& .MuiOutlinedInput-root': {
                color: '#016236',
                '& fieldset': {
                  borderColor: '#016236',
                },
                '&:hover fieldset': {
                  borderColor: '#016236',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#016236',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#016236',
                '&.Mui-focused': {
                  color: '#016236'
                }
              },
              '& .MuiSvgIcon-root': {
                color: '#016236',
              },
              '& .MuiSelect-select': {
                color: '#016236',
              },
              '& .MuiMenuItem-root': {
                color: '#016236',
              }
            }}
          >
            <InputLabel id="collection-select-label">Collections</InputLabel>
            <Select
              labelId="collection-select-label"
              id="collection-select"
              value={selectedTag}
              label="Collections"
              onChange={handleTagChange}
              MenuProps={{
                sx: { 
                  zIndex: 9997,
                  '& .MuiPaper-root': {
                    backgroundColor: '#fff'
                  }
                }
              }}
            >
              <MenuItem value="">
                <em>Accueil</em>
              </MenuItem>
              {usedTags.map((tag) => (
                <MenuItem key={tag.id} value={tag.id}>
                  {tag.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      {/* Collection Select and Sort Filters - Removed from here as they will be moved to the title bar */}
      
      {/* Collection Content */}
      {selectedTag && filteredImages.length > 0 ? (
        <Box>
          <Box sx={{ 
            width: '100vw', 
            position: 'relative',
            left: '50%',
            transform: 'translateX(-50%)',
            height: 'auto',
            mt: { xs: 1, sm: 2 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            px: { xs: 1, sm: 2, md: 3 },
            pb: { xs: 4, sm: 6 } // Add bottom padding
          }}>
            {/* Title bar with filters */}
            <Box sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              mb: { xs: 4, md: 4 },
              mt: { xs: 4, md: 4 },
              position: 'relative'
            }}>
              {/* Left - Empty on desktop, mobile filter button is handled above */}
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'flex-start', md: 'flex-start' } }}>
                {/* Desktop filter removed as it's already in navbar */}
              </Box>
              
              {/* Center - Title */}
              <Typography
                variant="h1"
                sx={{
                  fontFamily: '"Playwrite IT Moderna", sans-serif',
                  color: '#016236',
                  textAlign: 'center',
                  fontWeight: 500,
                  fontSize: { xs: '1.4rem', sm: '1.8rem' },
                  position: { md: 'absolute' },
                  left: { md: '50%' },
                  transform: { md: 'translateX(-50%)' },
                  width: { xs: 'auto', md: 'auto' },
                  flex: { xs: 1, md: 'none' },
                  mb: { md: 5 }
                }}
              >
                {selectedCollection?.name || 'Collection'}
              </Typography>
            </Box>
            
            <Swiper
              modules={[Navigation, Pagination, Keyboard, Autoplay]}
              spaceBetween={30}
              navigation={{
                enabled: false,
                hideOnClick: true
              }}
              pagination={{ 
                clickable: true,
                dynamicBullets: true,
                enabled: true,
                el: '.swiper-pagination-custom'
              }}
              keyboard={{ enabled: true }}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                  navigation: {
                    enabled: false
                  },
                  pagination: {
                    enabled: true
                  }
                },
                480: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                  navigation: {
                    enabled: false
                  },
                  pagination: {
                    enabled: true
                  }
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                  navigation: {
                    enabled: true
                  }
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                  navigation: {
                    enabled: true
                  }
                }
              }}
              style={{ 
                width: '100%',
                padding: '0 0 20px 0', // Add bottom padding to Swiper
                height: 'auto',
                display: 'flex',
                alignItems: 'flex-start',
                marginBottom: '20px'
              }}
              slidesPerView={1}
              loop={false}
            >
              {filteredImages.map((image, index) => (
                <SwiperSlide 
                  key={image.id}
                  style={{
                    aspectRatio: '1/1',
                    cursor: selectedTag ? 'default' : 'pointer',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    height: 'auto', // Ensure height is auto
                    marginBottom: '20px' // Add margin to slides
                  }}
                  onClick={selectedTag ? undefined : () => handleImageClick(index)}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                      borderRadius: 2,
                      overflow: 'hidden',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                      aspectRatio: '1/1'
                    }}
                  >
                    <Box
                      component="img"
                      src={image.url}
                      alt={image.title || 'Image de vélo'}
                      sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        transition: 'transform 0.3s ease',
                        '&:hover': {
                          transform: 'scale(1.05)',
                        },
                      }}
                    />
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
            
            {/* Custom pagination container */}
            <Box 
              className="swiper-pagination-custom" 
              sx={{ 
                width: '100%', 
                display: 'flex', 
                justifyContent: 'center', 
                mt: 2,
                mb: 2, // Reduced bottom margin
                position: 'relative', // Ensure it's relative
                '& .swiper-pagination-bullet': {
                  display: 'inline-block',
                  width: '12px',
                  height: '12px',
                  margin: '0 5px',
                  borderRadius: '50%',
                  background: '#016236',
                  opacity: 0.5,
                  transition: 'all 0.3s ease',
                },
                '& .swiper-pagination-bullet-active': {
                  opacity: 1,
                  width: '14px',
                  height: '14px',
                }
              }}
            />
          </Box>

          <Modal
            open={selectedImageIndex !== null}
            onClose={handleCloseModal}
            closeAfterTransition
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              zIndex: 9999,
            }}
          >
            <Fade in={selectedImageIndex !== null}>
              <ModalContent>
                <CloseButton onClick={handleCloseModal}>
                  <CloseIcon />
                </CloseButton>

                <NavigationButton
                  onClick={handlePrevImage}
                  sx={{ left: 16 }}
                >
                  <ChevronLeftIcon />
                </NavigationButton>

                <NavigationButton
                  onClick={handleNextImage}
                  sx={{ right: 16 }}
                >
                  <ChevronRightIcon />
                </NavigationButton>

                {selectedImageIndex !== null && (
                  <>
                    <ImageTitle variant="h5">
                      Vie à velo
                    </ImageTitle>
                    <ZoomedImage
                      src={filteredImages[selectedImageIndex].url}
                      alt={filteredImages[selectedImageIndex].title || 'Image de vélo'}
                    />
                  </>
                )}
              </ModalContent>
            </Fade>
          </Modal>
        </Box>
      ) : (
        <Box>
          <Grid 
            container 
            spacing={{ xs: 2, sm: 3 }}
            justifyContent={filteredImages.length <= 3 ? "center" : "flex-start"}
          >
            {!filteredImages.length ? (
              <Grid item xs={12}>
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                  <Typography variant="h5" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
                    Aucune image dans cette collection.
                  </Typography>
                </Box>
              </Grid>
            ) : (
              filteredImages.map((image, index) => (
                <Grid 
                  item 
                  xs={6} 
                  sm={6} 
                  md={4} 
                  key={image.id}
                >
                  <Box
                    onClick={selectedTag ? undefined : () => handleImageClick(index)}
                    sx={{
                      cursor: selectedTag ? 'default' : 'pointer',
                      width: '100%',
                      aspectRatio: '1/1',
                      position: 'relative',
                      borderRadius: 2,
                      overflow: 'hidden',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={image.url}
                      alt={image.title || 'Image de vélo'}
                      sx={{ 
                        objectFit: 'cover',
                        height: '100%',
                        width: '100%',
                        transition: 'transform 0.3s ease',
                        '&:hover': {
                          transform: 'scale(1.05)',
                        },
                      }}
                    />
                  </Box>
                </Grid>
              ))
            )}
          </Grid>

          <Modal
            open={selectedImageIndex !== null}
            onClose={handleCloseModal}
            closeAfterTransition
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              zIndex: 9999,
            }}
          >
            <Fade in={selectedImageIndex !== null}>
              <ModalContent>
                <CloseButton onClick={handleCloseModal}>
                  <CloseIcon />
                </CloseButton>

                <NavigationButton
                  onClick={handlePrevImage}
                  sx={{ left: 16 }}
                >
                  <ChevronLeftIcon />
                </NavigationButton>

                <NavigationButton
                  onClick={handleNextImage}
                  sx={{ right: 16 }}
                >
                  <ChevronRightIcon />
                </NavigationButton>

                {selectedImageIndex !== null && (
                  <>
                    <ImageTitle variant="h5">
                      Vie à vélo
                    </ImageTitle>
                    <ZoomedImage
                      src={filteredImages[selectedImageIndex].url}
                      alt={filteredImages[selectedImageIndex].title || 'Image de vélo'}
                    />
                  </>
                )}
              </ModalContent>
            </Fade>
          </Modal>
        </Box>
      )}
    </Box>
  );
};

export default Collection; 