import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { 
  Container, 
  Paper, 
  Typography, 
  TextField, 
  Button, 
  Alert,
  Box,
  Stepper,
  Step,
  StepLabel
} from '@mui/material';
import { API_URL } from '../services/api';

const isDevelopment = process.env.NODE_ENV === 'development';
const API_PREFIX = isDevelopment ? '/api' : '';

const emailSchema = z.object({
  email: z.string().email('Email invalide'),
});

const resetSchema = z.object({
  code: z.string().min(6, 'Le code doit contenir 6 caractères'),
  newPassword: z.string().min(6, 'Le nouveau mot de passe doit contenir au moins 6 caractères'),
  confirmPassword: z.string().min(6, 'La confirmation du mot de passe doit contenir au moins 6 caractères'),
}).refine((data: { newPassword: string, confirmPassword: string }) => data.newPassword === data.confirmPassword, {
  message: "Les mots de passe ne correspondent pas",
  path: ["confirmPassword"],
});

type EmailFormData = z.infer<typeof emailSchema>;
type ResetFormData = z.infer<typeof resetSchema>;

const steps = ['Saisir email', 'Vérification', 'Nouveau mot de passe'];

const RecoverPassword = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const emailForm = useForm<EmailFormData>({
    resolver: zodResolver(emailSchema),
  });

  const resetForm = useForm<ResetFormData>({
    resolver: zodResolver(resetSchema),
  });

  const handleEmailSubmit = async (data: EmailFormData) => {
    try {
      setError('');
      setEmail(data.email);
      
      await axios.post(`${API_URL}${API_PREFIX}/auth/recover-password`, { email: data.email });
      
      setSuccess('Un code de vérification a été envoyé à votre email');
      setActiveStep(1);
    } catch (err: any) {
      console.error('Erreur lors de la demande de récupération:', err);
      setError(err.response?.data?.message || 'Une erreur est survenue');
    }
  };

  const handleResetSubmit = async (data: ResetFormData) => {
    try {
      setError('');
      
      await axios.post(`${API_URL}${API_PREFIX}/auth/reset-password`, {
        email,
        code: data.code,
        newPassword: data.newPassword,
      });

      setSuccess('Mot de passe réinitialisé avec succès');
      setTimeout(() => {
        navigate('/login789');
      }, 2000);
    } catch (err: any) {
      console.error('Erreur lors de la réinitialisation:', err);
      setError(err.response?.data?.message || 'Une erreur est survenue');
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper 
        elevation={3} 
        sx={{ 
          p: 4, 
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          gap: 3
        }}
      >
        <Typography variant="h5" component="h1" align="center" gutterBottom>
          Récupération du mot de passe
        </Typography>

        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && (
          <form onSubmit={emailForm.handleSubmit(handleEmailSubmit)}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                {...emailForm.register('email')}
                label="Email"
                type="email"
                error={!!emailForm.formState.errors.email}
                helperText={emailForm.formState.errors.email?.message as string}
                fullWidth
              />

              {error && (
                <Alert severity="error">
                  {error}
                </Alert>
              )}

              <Button
                type="submit"
                variant="contained"
                disabled={emailForm.formState.isSubmitting}
                fullWidth
              >
                {emailForm.formState.isSubmitting ? 'Envoi...' : 'Envoyer le code'}
              </Button>

              <Button
                onClick={() => navigate('/login789')}
                variant="text"
                fullWidth
              >
                Retour à la connexion
              </Button>
            </Box>
          </form>
        )}

        {activeStep === 1 && (
          <form onSubmit={resetForm.handleSubmit(handleResetSubmit)}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {success && (
                <Alert severity="success" sx={{ mb: 2 }}>
                  {success}
                </Alert>
              )}

              <TextField
                {...resetForm.register('code')}
                label="Code de vérification"
                error={!!resetForm.formState.errors.code}
                helperText={resetForm.formState.errors.code?.message as string}
                fullWidth
              />

              <TextField
                {...resetForm.register('newPassword')}
                label="Nouveau mot de passe"
                type="password"
                error={!!resetForm.formState.errors.newPassword}
                helperText={resetForm.formState.errors.newPassword?.message as string}
                fullWidth
              />

              <TextField
                {...resetForm.register('confirmPassword')}
                label="Confirmer le mot de passe"
                type="password"
                error={!!resetForm.formState.errors.confirmPassword}
                helperText={resetForm.formState.errors.confirmPassword?.message as string}
                fullWidth
              />

              {error && (
                <Alert severity="error">
                  {error}
                </Alert>
              )}

              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  onClick={() => setActiveStep(0)}
                  variant="outlined"
                  fullWidth
                >
                  Retour
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={resetForm.formState.isSubmitting}
                  fullWidth
                >
                  {resetForm.formState.isSubmitting ? 'Réinitialisation...' : 'Réinitialiser'}
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Paper>
    </Container>
  );
};

export default RecoverPassword; 