import React from 'react';
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
  Typography,
  Divider,
} from '@mui/material';
import {
  Home as HomeIcon,
  PhotoLibrary as PhotoLibraryIcon,
  Add as AddIcon,
  DirectionsBike as BikeIcon,
  LockReset as LockResetIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth'; // Import useAuth hook

interface SidebarProps {
  activeSection: 'upload' | 'manage' | 'password';
  onSectionChange: (section: 'upload' | 'manage' | 'password') => void;
  drawerWidth: number;
}

export const Sidebar: React.FC<SidebarProps> = ({
  activeSection,
  onSectionChange,
  drawerWidth,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { logout } = useAuth(); // Get logout function from auth hook

  // Handler for logout
  const handleLogout = () => {
    logout();
    navigate('/login789');
  };

  return (
    <Box
      component="nav"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 8,
        bgcolor: "#212121",
        borderRight: `1px solid ${theme.palette.primary.dark}`,
        display: 'flex',
        flexDirection: 'column',
        zIndex: theme.zIndex.drawer,
      }}
    >
      {/* Logo Section */}
      <Box
        sx={{
          p: 2,
          pt: 2,
          mt: 9,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <BikeIcon sx={{ fontSize: 32, color: 'white' }} />
        <Typography variant="h6" sx={{ fontWeight: 600, color: 'white' }}>
          Tableau de bord
        </Typography>
      </Box>

      <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.12)' }} />

      {/* Navigation */}
      <List sx={{ flex: 1, px: 2, py: 2 }}>

        <ListItemButton
          selected={activeSection === 'manage'}
          onClick={() => onSectionChange('manage')}
          sx={{
            borderRadius: 1,
            color: 'white',
            '&.Mui-selected': {
              backgroundColor: 'rgba(255, 255, 255, 0.12)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.18)',
              },
            },
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.08)',
            },
          }}
        >
          <ListItemIcon sx={{ minWidth: 40, color: 'white' }}>
            <PhotoLibraryIcon />
          </ListItemIcon>
          <ListItemText
            primary="Gérer Images"
            primaryTypographyProps={{
              fontSize: '0.9rem',
              fontWeight: activeSection === 'manage' ? 600 : 400,
            }}
          />
        </ListItemButton>
        <ListItemButton
          selected={activeSection === 'upload'}
          onClick={() => onSectionChange('upload')}
          sx={{
            borderRadius: 1,
            mb: 1,
            color: 'white',
            '&.Mui-selected': {
              backgroundColor: 'rgba(255, 255, 255, 0.12)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.18)',
              },
            },
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.08)',
            },
          }}
        >
          <ListItemIcon sx={{ minWidth: 40, color: 'white' }}>
            <AddIcon />
          </ListItemIcon>
          <ListItemText
            primary="Ajouter Image"
            primaryTypographyProps={{
              fontSize: '0.9rem',
              fontWeight: activeSection === 'upload' ? 600 : 400,
            }}
          />
        </ListItemButton>

      </List>

      {/* Account Actions */}
      <Box sx={{ px: 2, pb: 2 }}>
        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.12)', mb: 2 }} />
        
        <ListItemButton
          selected={activeSection === 'password'}
          onClick={() => onSectionChange('password')}
          sx={{
            borderRadius: 1,
            color: 'white',
            '&.Mui-selected': {
              backgroundColor: 'rgba(255, 255, 255, 0.12)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.18)',
              },
            },
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.08)',
            },
          }}
        >
          <ListItemIcon sx={{ minWidth: 40, color: 'white' }}>
            <LockResetIcon />
          </ListItemIcon>
          <ListItemText
            primary="Changer mot de passe"
            primaryTypographyProps={{
              fontSize: '0.9rem',
              fontWeight: activeSection === 'password' ? 600 : 400,
            }}
          />
        </ListItemButton>
        
        <ListItemButton
          onClick={handleLogout}
          sx={{
            borderRadius: 1,
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.08)',
            },
          }}
        >
          <ListItemIcon sx={{ minWidth: 40, color: 'white' }}>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText
            primary="Déconnexion"
            primaryTypographyProps={{
              fontSize: '0.9rem',
            }}
          />
        </ListItemButton>
      </Box>

      {/* Version Info */}
      <Box sx={{ p: 2, borderTop: `1px solid rgba(255, 255, 255, 0.12)` }}>
        <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }} align="center" display="block">
          Version 1.0.0
        </Typography>
      </Box>
    </Box>
  );
}; 