import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  CircularProgress,
  Stack,
  TextField,
  Alert,
  Grid,
  Collapse,
  Autocomplete,
  Chip,
} from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import useStore from '../../store/useStore';
import { Tag } from '../../types';
import { api, API_URL } from '../../services/api';

const isDevelopment = process.env.NODE_ENV === 'development';
const API_PREFIX = isDevelopment ? '/api' : '';

interface FileWithPreview extends File {
  preview?: string;
}

interface ImageFormData {
  file: FileWithPreview | null;
  title: string;
  description: string;
  selectedTags: Tag[];
}

interface ImageUploaderProps {
  onSuccess?: (message: string) => void;
}

export const ImageUploader: React.FC<ImageUploaderProps> = ({ onSuccess }) => {
  const { addImages, tags, setTags } = useStore();
  const [formData, setFormData] = useState<ImageFormData>({
    file: null,
    title: '',
    description: '',
    selectedTags: [],
  });
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleDragEnter = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const processFile = (file: File) => {
    if (!file.type.startsWith('image/')) {
      setError('Seules les images sont acceptées');
      return;
    }

    setFormData(prev => ({
      ...prev,
      file: Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    }));
    setError(null);
  };

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      processFile(file);
    }
  }, []);

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      processFile(e.target.files[0]);
    }
    e.target.value = '';
  };

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const data = await api.getTags();
        if (data.success) {
          setTags(data.tags);
        }
      } catch (error) {
        console.error('Failed to fetch tags:', error);
      }
    };

    fetchTags();
  }, [setTags]);

  const handleUpload = async () => {
    if (!formData.file) {
      setError('Veuillez sélectionner une image');
      return;
    }

    if (!formData.title.trim()) {
      setError('Veuillez remplir le titre de l\'image');
      return;
    }

    setIsUploading(true);
    setError(null);
    setSuccess(null);

    try {
      const uploadFormData = new FormData();
      uploadFormData.append('images', formData.file);
      uploadFormData.append('titles[0]', formData.title);
      uploadFormData.append('descriptions[0]', formData.description);
      
      if (formData.selectedTags.length > 0) {
        const tagIds = formData.selectedTags.map(tag => tag.id);
        uploadFormData.append('tagIds[0]', JSON.stringify(tagIds));
      }

      const response = await fetch(`${API_URL}${API_PREFIX}/images/upload`, {
        method: 'POST',
        body: uploadFormData,
      });

      if (!response.ok) {
        const data = await response.json().catch(() => ({ error: 'Upload failed' }));
        throw new Error(data.error || 'Upload failed');
      }

      const data = await response.json();
      
      addImages(data.images);
      
      if (formData.file.preview) {
        URL.revokeObjectURL(formData.file.preview);
      }
      
      setFormData({
        file: null,
        title: '',
        description: '',
        selectedTags: [],
      });
      
      const successMessage = 'Image uploadée avec succès !';
      setSuccess(successMessage);
      
      if (onSuccess) {
        onSuccess(successMessage);
      }

      setTimeout(() => {
        setSuccess(null);
      }, 5000);
    } catch (error) {
      console.error('Erreur upload:', error);
      setError(error instanceof Error ? error.message : 'Upload failed');
    } finally {
      setIsUploading(false);
    }
  };

  const handleFormChange = (field: keyof Omit<ImageFormData, 'file' | 'selectedTags'>, value: string) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleTagsChange = (_event: React.SyntheticEvent, newValue: Tag[]) => {
    setFormData(prev => ({
      ...prev,
      selectedTags: newValue
    }));
  };

  return (
    <Box>
      {/* Alerts - Only show error alerts here, success will be in the header */}
      <Stack spacing={2} sx={{ mb: 3 }}>
        <Collapse in={!!error}>
          <Alert severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Collapse>
      </Stack>

      {/* Image Upload Form */}
      <Grid container spacing={3}>
        {/* Dropzone */}
        <Grid item xs={12} md={4}>
          <Paper
            onDragEnter={handleDragEnter}
            onDragOver={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            sx={{
              border: '2px dashed',
              borderColor: isDragging ? '#016236' : 'divider',
              borderRadius: 2,
              bgcolor: isDragging ? 'rgba(1, 98, 54, 0.08)' : 'background.paper',
              p: 3,
              textAlign: 'center',
              cursor: 'pointer',
              transition: 'all 0.2s ease',
              height: '100%',
              minHeight: 200,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleFileSelect}
              style={{ display: 'none' }}
              id="image-upload"
            />
            <label htmlFor="image-upload" style={{ width: '100%', cursor: 'pointer' }}>
              {formData.file?.preview ? (
                <Box
                  component="img"
                  src={formData.file.preview}
                  alt="Preview"
                  sx={{
                    width: '100%',
                    height: 150,
                    objectFit: 'contain',
                    mb: 2,
                  }}
                />
              ) : (
                <CloudUploadIcon sx={{ fontSize: 48, color: '#016236', mb: 2 }} />
              )}
              <Typography variant="body1" gutterBottom>
                {formData.file ? 'Changer d\'image' : 'Glissez une image ici'}
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                ou
              </Typography>
              <Button 
                variant="outlined" 
                component="span" 
                size="small"
                sx={{
                  color: '#016236',
                  borderColor: '#016236',
                  '&:hover': {
                    borderColor: '#016236',
                    backgroundColor: 'rgba(1, 98, 54, 0.04)'
                  }
                }}
              >
                Parcourir
              </Button>
            </label>
          </Paper>
        </Grid>

        {/* Form */}
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 3, height: '100%' }}>
            <Stack spacing={3}>
              {/* Display selected tags above the title input */}
              {formData.selectedTags.length > 0 && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {formData.selectedTags.map((tag) => (
                    <Chip
                      key={tag.id}
                      label={tag.name}
                      color="success"
                      onDelete={() => {
                        const newTags = formData.selectedTags.filter(t => t.id !== tag.id);
                        handleTagsChange(null as any, newTags);
                      }}
                      sx={{ 
                        m: 0.5,
                        borderColor: '#016236',
                        color: 'white'
                      }}
                    />
                  ))}
                </Box>
              )}
                            <Autocomplete
                multiple
                id="tags-selection"
                options={tags}
                value={formData.selectedTags}
                onChange={handleTagsChange}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderTags={() => null} // Don't render tags inside the input
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tags"
                    placeholder={formData.selectedTags.length ? "Ajouter plus de tags" : "Sélectionnez des tags"}
                    helperText="Sélectionnez un ou plusieurs tags pour cette image"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: '#016236'
                        }
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#016236'
                      }
                    }}
                  />
                )}
              />

              <TextField
                fullWidth
                label="Titre de l'image"
                required
                value={formData.title}
                onChange={(e) => handleFormChange('title', e.target.value)}
                placeholder="Donnez un titre à votre image"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#016236'
                    }
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#016236'
                  },
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#016236'
                  }
                }}
              />

              <TextField
                fullWidth
                label="Description"
                multiline
                rows={3}
                value={formData.description}
                onChange={(e) => handleFormChange('description', e.target.value)}
                placeholder="Ajoutez une description (optionnel)"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#016236'
                    }
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#016236'
                  },
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#016236'
                  }
                }}
              />



              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  onClick={handleUpload}
                  disabled={isUploading || !formData.title.trim() || !formData.file}
                  startIcon={isUploading ? <CircularProgress size={20} /> : undefined}
                  sx={{
                    bgcolor: '#016236',
                    '&:hover': {
                      bgcolor: '#016236e0'
                    }
                  }}
                >
                  {isUploading ? 'Upload en cours...' : 'Uploader l\'image'}
                </Button>
              </Box>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}; 