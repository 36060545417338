import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';
import veloImage from '../../assets/velo.jpg';

export const HomeContent: React.FC = () => {
  const content = {
    paragraphs: [
      `Ce site propose de collecter des images de cyclistes du quotidien.`,
      `Le vélo au quotidien est une discipline très souple: pas de règle, pas de compétition.Chacun a une bonne raison de s'y mettre: changer d'air, faire ses courses, emmener les enfants, aller voir un copain, gagner du temps, profiter du temps... On choisit un vélo et c'est parti pour un bout de chemin.`,
      `En parcourant les images de ce site, c'est l'occasion d'explorer la diversité du cyclisme au quotidien, de mieux comprendre cet art de vivre. Le vélo permet quand même, entre autres choses, de prendre du plaisir et d'augmenter la réalité. Ce n'est pas rien. C'est important de le partager et c'est l'ambition de cet espace.`,
      `En route!`
    ]
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 0, mb: 0 }}>
      <Grid 
        container 
        spacing={4} 
        alignItems="stretch"
        sx={{ minHeight: { xs: 'auto', md: '600px' } }}
      >
        {/* Image Section - Shows first on mobile */}
        <Grid 
          item 
          xs={12} 
          md={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            order: { xs: 1, md: 2 }
          }}
        >
          <Box
            component="img"
            src={veloImage}
            alt="Cycliste dans la forêt"
            sx={{
              width: '90%',
              height: 'auto',
              maxHeight: '600px',
              objectFit: 'contain',
              borderRadius: 2,
              boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)',
              opacity: 0.95
            }}
          />
        </Grid>

        {/* Text Section - Shows second on mobile */}
        <Grid 
          item 
          xs={12} 
          md={6} 
          sx={{
            display: 'flex',
            alignItems: 'center',
            order: { xs: 2, md: 1 },
            mt: { xs: 2, md: 0 }
          }}
        >
          <Box sx={{ width: '100%' }}>
            {content.paragraphs.map((paragraph, index) => (
              <Typography
                key={index}
                variant="body1"
                paragraph
                sx={{
                  fontSize: { xs: '1rem', md: '1.1rem' },
                  lineHeight: 1.6,
                  color: 'text.primary',
                  mb: index === content.paragraphs.length - 1 ? 0 : 3,
                  fontFamily: '"Playwrite IT Moderna", sans-serif',
                  ...(index === content.paragraphs.length - 1 && {
                    fontStyle: 'italic',
                    fontSize: { xs: '1.1rem', md: '1.2rem' },
                    textAlign: 'right',
                    pr: { xs: 2, md: 4 }
                  })
                }}
              >
                {paragraph}
              </Typography>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}; 