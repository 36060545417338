import axios from 'axios';
import { HomeContent } from '../types/home';

// Get API URL from environment variables based on environment
const isDevelopment = process.env.NODE_ENV === 'development';
export const API_URL = process.env.REACT_APP_API_URL || (isDevelopment ? 'http://localhost:5000' : 'https://lavieavelo-server.vercel.app');

// In development, we need /api prefix. In production, Vercel handles this
const API_PREFIX = isDevelopment ? '/api' : '';

if (!process.env.REACT_APP_API_URL) {
  console.warn(`⚠️ REACT_APP_API_URL is not defined in environment variables, using ${API_URL}`);
}

console.log(`🌐 API URL: ${API_URL} (${process.env.NODE_ENV} mode)`);

// Configure Axios with timeout and baseURL
const apiClient = axios.create({
  baseURL: API_URL,
  timeout: 15000, // Increased timeout
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  withCredentials: true // Enable credentials for CORS
});

// Request interceptor for development logging
if (process.env.NODE_ENV === 'development') {
  apiClient.interceptors.request.use(
    (config) => {
      console.log(`📡 ${config.method?.toUpperCase()} ${config.url}`);
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
      if (axios.isAxiosError(error)) {
        console.error('❌ API Error:', {
          url: error.config?.url,
          method: error.config?.method,
          status: error.response?.status,
          message: error.response?.data?.message || error.message,
        });
      }
      return Promise.reject(error);
    }
  );
}

// API endpoints
export const api = {
  // Tags
  getTags: async () => {
    try {
      const response = await apiClient.get(`${API_PREFIX}/tags`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch tags:', error);
      throw error;
    }
  },

  // Images
  getImages: async () => {
    try {
      const response = await apiClient.get(`${API_PREFIX}/images`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch images:', error);
      throw error;
    }
  },

  // Home content
  getHomeContent: async (): Promise<HomeContent> => {
    try {
      const response = await apiClient.get(`${API_PREFIX}/content/home`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch home content:', error);
      throw error;
    }
  },

  updateHomeContent: async (content: Omit<HomeContent, 'id' | 'updatedAt'>): Promise<HomeContent> => {
    try {
      const response = await apiClient.put(`${API_PREFIX}/content/home`, content);
      return response.data;
    } catch (error) {
      console.error('Failed to update home content:', error);
      throw error;
    }
  },
}; 