import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Chip,
  Paper,
  Stack,
  Alert,
  Collapse,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useStore from '../../store/useStore';
import { Tag } from '../../types';
import { api, API_URL } from '../../services/api';

const isDevelopment = process.env.NODE_ENV === 'development';
const API_PREFIX = isDevelopment ? '/api' : '';

interface TagManagerProps {
  onSuccess?: (message: string) => void;
}

export const TagManager: React.FC<TagManagerProps> = ({ onSuccess }) => {
  const { tags, setTags, addTag } = useStore();
  const [newTagName, setNewTagName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [visibleTagsCount, setVisibleTagsCount] = useState(20);
  
  // Edit mode state
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editingTag, setEditingTag] = useState<Tag | null>(null);
  const [editTagName, setEditTagName] = useState('');

  // Delete confirmation state
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [tagToDelete, setTagToDelete] = useState<Tag | null>(null);

  // Fetch tags on component mount
  useEffect(() => {
    const fetchTags = async () => {
      setIsLoading(true);
      try {
        const data = await api.getTags();
        setTags(data.tags || []);
      } catch (error) {
        console.error('Erreur:', error);
        setError(error instanceof Error ? error.message : 'Une erreur est survenue');
      } finally {
        setIsLoading(false);
      }
    };

    fetchTags();
  }, [setTags]);

  const handleAddTag = async () => {
    if (!newTagName.trim()) {
      setError('Le nom du tag ne peut pas être vide');
      return;
    }

    setIsLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await fetch(`${API_URL}${API_PREFIX}/tags`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: newTagName.trim() }),
      });

      if (!response.ok) {
        const data = await response.json().catch(() => ({ error: 'Erreur lors de la création du tag' }));
        throw new Error(data.error || 'Erreur lors de la création du tag');
      }

      const data = await response.json();
      addTag(data.tag);
      setNewTagName('');
      
      const successMessage = 'Tag créé avec succès !';
      setSuccess(successMessage);
      
      if (onSuccess) {
        onSuccess(successMessage);
      }

      setTimeout(() => {
        setSuccess(null);
      }, 3000);
    } catch (error) {
      console.error('Erreur:', error);
      setError(error instanceof Error ? error.message : 'Une erreur est survenue');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTag = async (id: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_URL}${API_PREFIX}/tags/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la suppression du tag');
      }

      setTags(tags.filter(tag => tag.id !== id));
      
      const successMessage = 'Tag supprimé avec succès !';
      setSuccess(successMessage);
      
      if (onSuccess) {
        onSuccess(successMessage);
      }

      setTimeout(() => {
        setSuccess(null);
      }, 3000);
    } catch (error) {
      console.error('Erreur:', error);
      setError(error instanceof Error ? error.message : 'Une erreur est survenue');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadMoreTags = () => {
    setVisibleTagsCount(prevCount => prevCount + 20);
  };

  const handleEditClick = (tag: Tag) => {
    setEditingTag(tag);
    setEditTagName(tag.name);
    setEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setEditingTag(null);
    setEditTagName('');
  };

  const handleEditSave = async () => {
    if (!editingTag || !editTagName.trim()) return;

    setIsLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await fetch(`${API_URL}${API_PREFIX}/tags/${editingTag.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: editTagName.trim() }),
      });

      if (!response.ok) {
        const data = await response.json().catch(() => ({ error: 'Erreur lors de la mise à jour du tag' }));
        throw new Error(data.error || 'Erreur lors de la mise à jour du tag');
      }

      const data = await response.json();
      
      setTags(tags.map(tag => 
        tag.id === editingTag.id ? { ...tag, name: data.tag.name } : tag
      ));

      handleEditClose();
      
      const successMessage = 'Tag modifié avec succès !';
      setSuccess(successMessage);
      
      if (onSuccess) {
        onSuccess(successMessage);
      }

      setTimeout(() => {
        setSuccess(null);
      }, 3000);
    } catch (error) {
      console.error('Erreur:', error);
      setError(error instanceof Error ? error.message : 'Une erreur est survenue');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (tag: Tag) => {
    setTagToDelete(tag);
    setDeleteConfirmOpen(true);
    if (editDialogOpen) {
      handleEditClose();
    }
  };

  const handleDeleteConfirmClose = () => {
    setDeleteConfirmOpen(false);
    setTagToDelete(null);
  };

  const handleDeleteConfirm = async () => {
    if (tagToDelete) {
      await handleDeleteTag(tagToDelete.id);
      handleDeleteConfirmClose();
    }
  };

  // Sort tags alphabetically by name
  const sortedTags = [...tags].sort((a, b) => a.name.localeCompare(b.name));

  // Get the visible tags based on the current count
  const visibleTags = sortedTags.slice(0, visibleTagsCount);
  const hasMoreTags = sortedTags.length > visibleTagsCount;

  return (
    <Box sx={{ mt: 3 }}>
      <Paper sx={{ p: 3 }}>
        {/* Header */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h6"  fontWeight="medium">
            Gestion des tags
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <TextField
              size="small"
              placeholder="Nom du tag"
              value={newTagName}
              onChange={(e) => setNewTagName(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleAddTag()}
              disabled={isLoading}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: '#016236'
                  }
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#016236'
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#016236'
                }
              }}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <AddIcon />}
              onClick={handleAddTag}
              disabled={isLoading || !newTagName.trim()}
              sx={{
                bgcolor: '#016236',
                '&:hover': {
                  bgcolor: '#016236e0'
                }
              }}
            >
              Ajouter un nouveau tag
            </Button>
          </Box>
        </Box>

        {/* Alerts - Only show error alerts here, success will be in the header */}
        <Stack spacing={2} sx={{ mb: 0 }}>
          <Collapse in={!!error}>
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          </Collapse>
        </Stack>

        {/* Tags Display */}
        <Box sx={{ mt: 0 }}>
          {isLoading && tags.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress size={30} />
            </Box>
          ) : tags.length === 0 ? (
            <Typography color="text.secondary" align="center">
              Aucun tag disponible. Créez votre premier tag !
            </Typography>
          ) : (
            <>
              <Grid container spacing={1}>
                {visibleTags.map((tag) => (
                  <Grid item key={tag.id}>
                    <Chip
                      label={tag.name}
                      onDelete={() => handleDeleteClick(tag)}
                      color="primary"
                      variant="outlined"
                      sx={{ 
                        m: 0.5,
                        borderColor: '#016236',
                        color: '#016236',
                        '& .MuiChip-deleteIcon': {
                          color: '#016236'
                        }
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
              
              {hasMoreTags && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <Tooltip title={`Afficher 20 tags supplémentaires (${sortedTags.length - visibleTagsCount} restants)`}>
                    <IconButton 
                      color="primary" 
                      onClick={handleLoadMoreTags}
                      size="small"
                      sx={{ 
                        border: '1px dashed',
                        borderColor: '#016236',
                        color: '#016236',
                        p: 1,
                        '&:hover': {
                          bgcolor: 'rgba(1, 98, 54, 0.08)'
                        }
                      }}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </>
          )}
        </Box>

        {/* Edit Dialog */}
        <Dialog open={editDialogOpen} onClose={handleEditClose}>
          <DialogTitle>Modifier le tag</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ mb: 2 }}>
              Modifiez le nom du tag ou supprimez-le définitivement.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Nom du tag"
              fullWidth
              variant="outlined"
              value={editTagName}
              onChange={(e) => setEditTagName(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleEditSave()}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: '#016236'
                  }
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#016236'
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#016236'
                }
              }}
            />
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between', px: 3, pb: 2 }}>
            <Button
              onClick={() => {
                if (editingTag) {
                  handleDeleteClick(editingTag);
                }
              }}
              color="error"
              startIcon={<DeleteIcon />}
            >
              Supprimer
            </Button>
            <Box>
              <Button onClick={handleEditClose} sx={{ 
                mr: 1,
                color: '#016236',
                '&:hover': {
                  bgcolor: 'rgba(1, 98, 54, 0.04)'
                }
              }}>
                Annuler
              </Button>
              <Button 
                onClick={handleEditSave}
                variant="contained"
                disabled={!editTagName.trim() || editTagName.trim() === editingTag?.name}
                sx={{
                  bgcolor: '#016236',
                  '&:hover': {
                    bgcolor: '#016236e0'
                  }
                }}
              >
                Enregistrer
              </Button>
            </Box>
          </DialogActions>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={deleteConfirmOpen}
          onClose={handleDeleteConfirmClose}
          aria-labelledby="delete-dialog-title"
          aria-describedby="delete-dialog-description"
        >
          <DialogTitle id="delete-dialog-title">
            Confirmer la suppression
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-dialog-description">
              Êtes-vous sûr de vouloir supprimer le tag "{tagToDelete?.name}" ? Cette action est irréversible.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 2 }}>
            <Button onClick={handleDeleteConfirmClose} sx={{ 
              color: '#016236',
              '&:hover': {
                bgcolor: 'rgba(1, 98, 54, 0.04)'
              }
            }}>
              Annuler
            </Button>
            <Button
              onClick={handleDeleteConfirm}
              color="error"
              variant="contained"
              startIcon={<DeleteIcon />}
            >
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Box>
  );
};

export default TagManager; 