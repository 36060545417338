import { create } from 'zustand';
import { BikeImage, HomeContent, Tag } from '../types';

interface Store {
  images: BikeImage[];
  homeContent: HomeContent | null;
  tags: Tag[];
  isLoading: boolean;
  error: string | null;
  selectedTag: string;
  setSelectedTag: (tag: string) => void;
  setImages: (images: BikeImage[] | ((prev: BikeImage[]) => BikeImage[])) => void;
  addImage: (image: BikeImage) => void;
  addImages: (images: BikeImage[]) => void;
  removeImage: (publicId: string) => void;
  setHomeContent: (content: HomeContent) => void;
  setTags: (tags: Tag[]) => void;
  addTag: (tag: Tag) => void;
  removeTag: (id: string) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
}

const useStore = create<Store>((set) => ({
  images: [],
  homeContent: null,
  tags: [],
  isLoading: false,
  error: null,
  selectedTag: '',
  setSelectedTag: (tag) => set({ selectedTag: tag }),
  setImages: (images) => set((state) => ({
    images: typeof images === 'function' ? images(state.images) : images
  })),
  addImage: (image) => set((state) => ({ images: [...state.images, image] })),
  addImages: (newImages) => set((state) => ({ 
    images: [...state.images, ...newImages] 
  })),
  removeImage: (publicId) => set((state) => ({
    images: state.images.filter((img) => img.publicId !== publicId)
  })),
  setHomeContent: (content) => set({ homeContent: content }),
  setTags: (tags) => set({ tags }),
  addTag: (tag) => set((state) => ({ tags: [...state.tags, tag] })),
  removeTag: (id) => set((state) => ({
    tags: state.tags.filter((tag) => tag.id !== id)
  })),
  setLoading: (loading) => set({ isLoading: loading }),
  setError: (error) => set({ error })
}));

export default useStore; 